import React, { useEffect, useState } from 'react';
import Slider from 'react-slick';

import { Grid, makeStyles, Container } from '@material-ui/core';
import useWindowScroll from '@react-hook/window-scroll';
import { Link } from 'gatsby';
import companyImg from '../../images/home/company.png';
import portfolio from '../../images/home/portfolio.png';
import prices from '../../images/home/prices.png';
import target from '../../images/home/target.png';
import mblogImg from '../../images/home/mblog_line.png';

import ma from '../../images/carousel/m4.png';
import mb from '../../images/carousel/m1.png';
import mc from '../../images/carousel/m2.png';
import md from '../../images/carousel/m3.png';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import BlogLink from '../../components/organisms/BlogLink';
import ReservationForm from '../../components/organisms/ReservationForm';
import CustomerCounter from '../../components/organisms/CustomerCounter';
import Gallery from '../../components/organisms/Gallery';
import ContentTitle from '../../components/molecules/ContentTitle';
import theme from '../../common/theme';
import MobileLayout from '../../components/templates/MobileLayout';

const useStyles = makeStyles((_theme) => ({
  paper: {
    width: '100%',
    marginTop: _theme.spacing(2),
    marginBottom: _theme.spacing(2),
    padding: _theme.spacing(2),
  },
  root: {
    backgroundColor: 'gray',
  },
  formGroup: { marginTop: _theme.spacing(1) },
  countPaper: {},
}));

const brandImageStyle = { width: '100%' };

const alt = '줄눈의 모든것 성실시공,완벽시공,철저한 AS, 문의전화 : 010-7669-6017';

const Index = () => {
  const classes = useStyles();
  const scrollY = useWindowScroll(60 /* fps */);
  const [customerCount, setCustomerCount] = useState(0);
  const [isCustomerCountLoad, setIsCustomerCountLoad] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      const url = `${process.env.REACT_APP_API_URL}/api/customer-count`;
      const response = await fetch(url);
      if (response.ok) {
        const responseJson = await response.json();
        setCustomerCount(responseJson.data);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    if (scrollY >= 400) {
      setIsCustomerCountLoad(true);
    } else {
      setIsCustomerCountLoad(false);
    }
  }, [scrollY]);

  return (
    <MobileLayout>
      <div style={{ marginBottom: '50px' }}>
        <Slider
          dots
          infinite
          autoplay
          autoplaySpeed={4000}
          speed={500}
          slidesToShow={1}
          slidesToScroll={1}
          arrows={false}
        >
          <img src={ma} alt={alt} />
          <img src={mb} alt={alt} />
          <img src={mc} alt={alt} />
          <img src={md} alt={alt} />

        </Slider>
      </div>
      <Container>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            {process.env.REACT_APP_SHOW_BLOGLINK === 'true' && (
              <BlogLink>
                <img
                  src={mblogImg}
                  style={{
                    display: 'block',
                    margin: 'auto',
                    width: '100%',
                  }}
                  alt="블로그 바로가기"
                />
              </BlogLink>
            )}
          </Grid>
          <Grid item xs={6}>
            <Link to="/m/company">
              <img
                src={companyImg}
                alt="회사소개"
                style={brandImageStyle}
              />
            </Link>
          </Grid>
          <Grid item xs={6}>
            <Link to="/m/price">
              <img
                src={prices}
                style={brandImageStyle}
                alt="줄눈시공가격"
              />
            </Link>
          </Grid>
          <Grid item xs={6}>
            <BlogLink>
              <img
                src={portfolio}
                style={brandImageStyle}
                alt="줄눈시공사례"
              />
            </BlogLink>
          </Grid>
          <Grid item xs={6}>
            <Link to="/m/targets">
              <img
                src={target}
                style={brandImageStyle}
                alt="줄눈시공대상"
              />
            </Link>
          </Grid>
        </Grid>
        <Grid
          container
          spacing={3}
          style={{
            backgroundColor: 'gray',
            marginTop: '40px',
            marginBottom: '40px',
          }}
        >
          <Grid
            container
            direction="row"
            justify="space-between"
            alignItems="center"
            style={{ marginTop: theme.spacing(2) }}
          >
            <Grid container className={classes.root}>
              <Grid item xs={12} lg={12}>
                <CustomerCounter
                  isLoad={isCustomerCountLoad}
                  count={customerCount}
                />
              </Grid>
              <Grid item xs={12} lg={12}>
                <ReservationForm />
              </Grid>
            </Grid>
          </Grid>
        </Grid>
        <div style={{ marginTop: 50 }}>
          <BlogLink>
            <ContentTitle title="시공 갤러리" isClearAfter />
            <Gallery />
          </BlogLink>
        </div>
      </Container>
    </MobileLayout>
  );
};

Index.propTypes = {};

export default Index;
